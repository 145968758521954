import * as zod from 'zod';

import { IntegrationInterface } from '@sb/integrations/io/types';

export const CustomDeviceConfiguration = zod.object({
  kind: zod.literal('CustomDevice'),
  name: zod.string().default('Custom Device'),
  interfaces: IntegrationInterface.array().default([]),
});

export type CustomDeviceConfiguration = zod.infer<
  typeof CustomDeviceConfiguration
>;
